<template>
  <div>
    <q-form ref="myForm" class="add">
      <div class='maxgao'>
          <q-card flat bordered class="my-card add">
            <q-card-section>
            <div class="row justify-between">
                <div style="padding: 0 5px;" class="col-16 col-md-3">
                    <!-- 订单号 -->
                    <q-input :disable='true' class="add" :dense='true' outlined  v-model="checkAllData.orderId" label="订单号*" lazy-rules :rules='[required.kong]' />
                    <!-- 资料审核员 -->
                    <q-input :disable="true" class="add" :dense="true" outlined v-model="checkAllData.reconstructionVo.informationCheckUser" label="资料审核员" lazy-rules :rules="[required.kong]"/>
                    <!-- 科室选择 -->
                    <q-input :disable="true" class="add" :dense="true" outlined v-model="checkAllData.departName" label="科室*" lazy-rules :rules="[required.kong]"/>
                    <!-- 订单金额 -->
                    <q-input :disable='true' class="add" type="number" :dense='true' outlined  v-model="checkAllData.orderAmt" label="订单金额*" lazy-rules :rules='[required.orderlimit]' />
                    <!-- 加急金额 -->
                    <q-input class="add" :disable="true" :dense='true' outlined  v-model="checkDatas.urgentAmt" label="加急金额" lazy-rules :rules='[required.kong]'/>
                </div>

                <div style="padding: 0 5px;" class="col-16 col-md-3">
                    <!-- 患者姓名 -->
                    <q-input :disable='true' class="add" :dense='true' outlined  v-model="checkAllData.patientName" label="患者姓名*" lazy-rules :rules='[required.patientNameRules,required.patientNameLength]' />
                    <!-- 省份选择 -->
                    <q-input :disable="true" class="add" :dense="true" outlined v-model="checkAllData.provinceName" label="省份*" lazy-rules :rules="[required.kong]"/>
                    <!-- 套餐选择 -->
                    <q-input :disable="true" class="add" :dense="true" outlined v-model="checkAllData.comboName" label="套餐*" lazy-rules :rules="[required.kong]"/>
                    <!-- 住院号 -->
                    <q-input 
                        class="add" 
                        :disable='true' 
                        :dense='true' 
                        outlined  
                        v-model="checkAllData.hospNumber" 
                        label="住院号" 
                        lazy-rules 
                        :rules='[required.kong]'
                    />
                    <!-- 回款金额 -->
                    <q-input v-if="checkDatas.returnedAmtDisable" class="add" :disable="true" :dense='true' outlined  v-model="checkDatas.orderReturnedAmt" label="回款金额" lazy-rules :rules='[required.kong]'/>

                </div>

                <div style="padding: 0 5px;" class="col-16 col-md-3">
                    <!-- 医生姓名 -->
                    <q-input :disable='true' class="add" :dense='true' outlined  v-model="checkAllData.doctorName" label="医生姓名*" lazy-rules :rules='[required.doctorNameRules]'/>
                    <!-- 城市选择 -->
                    <q-input :disable="true" class="add" :dense="true" outlined v-model="checkAllData.cityName" label="城市*" lazy-rules :rules="[required.kong]"/>
                    <!-- 主管医生下拉框 doctorselect -->
                    <q-input
                        :disable="true"
                        class="add"
                        :dense="true"
                        outlined
                        v-model="checkAllData.attendingDoctor"
                        label="主管医师"
                        lazy-rules
                        :rules="[required.kong]"
                    />
                    <!-- 优惠码 -->
                    <q-input class="add" :disable="true" :dense='true' outlined  v-model="checkDatas.discountCode" label="优惠码" lazy-rules :rules='[required.kong]'/>

                </div>

                <div style="padding: 0 5px;" class="col-16 col-md-3">
                    <!-- 重建人员 -->
                    <q-input :disable="true" class="add" :dense="true" outlined v-model="checkAllData.reconstructionVo.reconUser" label="重建人员" lazy-rules :rules="[required.kong]"/>
                    <!-- 医院选择 -->
                    <q-input :disable="true" class="add" :dense="true" outlined v-model="checkAllData.hospName" label="医院*" lazy-rules :rules="[required.kong]"/>
                    <!-- 手术时间 -->
                    <surgeryDate class='add' :disable='true' :model.sync='checkAllData.surgeryDate'/>
                    <!-- 折扣力度 -->
                    <q-input class="add" :disable="true" :dense='true' outlined  v-model="checkDatas.discountPercent" label="折扣力度" lazy-rules :rules='[required.kong]'/>

                </div>

                <!-- <div class="col-5"></div>
                <div class="col-5"></div> -->

                <div class='add' style="padding: 0 5px;">
                    <!-- 驳回理由 -->
                    <q-input
                        :disable='true'
                        :dense='true' 
                        outlined  
                        autogrow
                        type="textarea"
                        v-model="checkDatas.reject" 
                        label="驳回原因" 
                        lazy-rules 
                        :rules="[required.kong]"
                    />
                </div>
                <div class='add' style="padding: 0 5px;">
                    <!-- 诊断说明 -->
                    <q-input
                        :disable="true"
                        :dense="true"
                        outlined
                        autogrow
                        type="textarea"
                        v-model="checkAllData.diagnoseDesc"
                        label="诊断说明"
                        lazy-rules
                        :rules="[required.kong]"
                    />
                </div>
                <div class='add' style="padding: 0 5px;">
                    <!-- 订单备注信息 -->
                    <q-input
                        :disable='true' 
                        :dense='true' 
                        outlined  
                        autogrow
                        type="textarea"
                        v-model="checkAllData.orderDesc" 
                        label="订单备注信息" 
                        lazy-rules 
                        :rules="[required.kong]"
                    />
                </div>
            </div>
            </q-card-section>
        </q-card>
        <q-card flat bordered class="my-card add">
            <q-card-section>
                <!-- <tabulationdate
                    :orderList='checkDatas.checkReportFilesList'
                    :orderid="checkAllDatas.orderId"
                    :prop='"informationFileName"'
                    :label='"检查报告"'
                /> -->
                <el-table :data="checkDatas.reconstructionReportList" style="width: 100%">
                    <el-table-column label="重建报告" prop="reconstructionReport" show-overflow-tooltip></el-table-column>
                    <el-table-column>
                        <template slot-scope="time">
                            {{ time.row.reconstructionReport? time.row.createTime:''}}
                        </template>
                    </el-table-column>

                    <!-- 空白占位格 -->
                    <el-table-column align='center' label="" width='130'>
                 
                    </el-table-column>
                    <el-table-column align="right" width='150'>
                        <template slot="header">
                            <el-button size="small" type="primary" @click='checkjieguo'>查看重建结果</el-button>
                        </template>
                        <template slot-scope="scope">
                            <el-button
                            v-if="scope.row.reconstructionReport"
                            size="mini"
                            type="primary"
                            icon="iconfont icon-xiazai"
                            @click="recondownload(scope.row)"
                            ></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </q-card-section>
        </q-card>
        <q-card flat bordered class="my-card add">
            <q-card-section>
                <!-- <tabulationdate
                    :orderList='checkDatas.checkReportFilesList'
                    :orderid="checkAllDatas.orderId"
                    :prop='"informationFileName"'
                    :label='"影像资料"'
                    ></tabulationdate> -->
                    <el-table 
                        v-loading="ordervisible"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(55,55,55,0.2)"
                        :data="checkDatas.dcmReportFilesList" 
                        style="width: 100%"
                    >
                        <el-table-column label="影像资料" prop="informationFileName" show-overflow-tooltip></el-table-column>
                        <el-table-column label="" prop="createTime"></el-table-column>

                        <el-table-column align='center' label="资料状态" width='130'>
                            <template slot="header"> 
                                资料状态<i style="margin-left: 12px;" class="el-icon-refresh-left" @click="refresh"></i>
                            </template>

                            <template slot-scope="scope">
                                <q-chip v-if="scope.row.processFlg != 1" square :color="scope.row.processFlg==0?'primary':'red'" text-color="white">
                                    {{scope.row.processFlg==0?'处理中':'处理失败'}}
                                </q-chip>
                                <q-tooltip v-if="scope.row.errMsg">
                                    {{scope.row.errMsg}}
                                </q-tooltip>
                            </template>
                        </el-table-column>

                        <el-table-column align="right" width='150'>
                            <template slot="header">
                            </template>
                            <template slot-scope="scope">
                                <el-button
                                size="mini"
                                type="primary"
                                icon="iconfont icon-xiazai"
                                @click="yxdownload(scope.row)"
                                ></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </q-card-section>
            </q-card>
      </div>
      <div class="caozuobtn">
          <q-btn
            class="checkbtn"
            label="取消"
            color="blue-6"
            @click="closedialog"
          />
          <q-btn
            :disable='checkDatas.disable'
            class="checkbtn"
            label="驳回"
            color="negative"
            @click="reject"
          />
          <q-btn
            :disable='checkDatas.disable'
            class="checkbtn"
            label="通过"
            color="blue-6"
            @click="agreecheck"
          />
      </div>
    </q-form>
    <!-- 驳回弹出框 -->
    <q-dialog v-model="card" persistent @hide='hide'>
      <q-card style="width: 500px">
        <q-card-section>
          <div class="text-h6">驳回理由</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
            <q-form ref="reject">
                <q-input
                class='rejectinput'
                :dense='true' 
                outlined 
                type="textarea"
                v-model="rejectreason" 
                label="驳回理由" 
                lazy-rules 
                :rules="[required.reject]"
                />
            </q-form>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="取消" v-close-popup />
          <q-btn :disable='rejectreason==""? true : false' label="驳回" @click='rejectcheck' color="negative"/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- 普通影像资料下载链接生成弹窗 -->
    <yxDown
        ref='yxDown'
        :downdata.sync='downdata'
        @downhide='downhide'
    />

  </div>
</template>

<script>
import { Loading } from 'element-ui';

import { informationdownload } from '@/api/components/informationdownload.js' //影像资料下载
import yxDown from '@/components/Down/informationDown.vue' // 影像链接生成弹窗组件

import { orderCheck,reconCheckreject,reconCheckagree } from '@/api/orderresult.js'; // 重建结果审核接口

import { mapState } from 'vuex' //vuex获取数据
import axios from 'axios'

// import tabulationdate from '@/components/Order/tabulationdate' // 资料表格组件
import surgeryDate from '@/components/Order/surgeryDate' // 时间选择组件
import selectq from '@/components/Order/select' // 普通下拉框组件
import selectp from '@/components/Order/select/provinceselect.vue' // 省份下拉框组件
import selectc from '@/components/Order/select/cityselect.vue' // 城市下拉框组件
import selecth from '@/components/Order/select/hospselect.vue' // 医院下拉框组件
import selectd from '@/components/Order/select/departsselect.vue' // 科室下拉框组件
import comboselect from '@/components/Order/select/comboselect.vue' // 套餐下拉框组件
import  {required}  from '@/api/components/required.js' // 表单验证规则

import { makeDebounce } from "@/utils/debounce.js";  
import { notifys } from '@/api/components/Notify.js'

export default {
    props:{
        // checkAlllist: { //所有城市省份医院信息
        //     type: Object,
        //     required: true,
        // },
        checkAllData:{ //修改订单的数据
            type: Object,
            required: true,
        },
        checkData:{ //所选的城市
            type: Object,
            required: true,
        }
    },
    created(){
    },
    data() {
        return {
            ordervisible: false,

            text:'',
            required,
            card: false, // 控制驳回文本弹出框
            rejectreason: '', // 绑定驳回理由

            options:{
                target:'document.body',
                text:'下载链接获取中,如果时间较长请耐心等待',
            },
            
            downdata:{
                downconfig: false,
                downcheckprogress: 0, //进度条
                downcheckprogressLabel: '', //进度百分比
                downfileName: '', // 文件名
                downjindu: null, //用于储存定时器
                downstepName:'', //压缩步骤
                timerList:[],
                downdataA:true, //
                downdataB: true,
            },
        };
    },
    components:{
        selectq, // 普通下拉组件
        selectp, // 省份下拉框组件,
        selectc, //城市下拉框
        selecth, // 医院下拉框
        selectd, // 科室下拉框
        comboselect, //套餐下拉框
        surgeryDate, // 手术时间
        yxDown, //影像链接生成弹窗组件
        // tabulationdate // 资料表格
    },
    computed:{
        ...mapState([
            'orderStatus', //订单状态
            'orderType',//订单类型
            'payStatus', // 支付状态
            'isPay', // 代理商是否收款
        ]),
        checkAllDatas:{ //agreeAllData
            get(){
                return this.checkAllData
            },
            set(v){
                this.$emit('update:checkAllData',v)
            }
        },
        checkDatas:{  //agreeData
            get(){
                return this.checkData
            },
            set(v){
                this.$emit('update:checkData',v)
            }
        }
    },
    watch:{
    },
    methods: {
        //刷新影像资料状态
        refresh:makeDebounce(async function(row) {
            console.log('刷新',this.checkAllDatas);
            this.ordervisible = true
            setTimeout(async() =>{
                const {data:res} = await orderCheck({orderId:this.checkAllDatas.orderId})
                if(res.code===20000){
                    notifys('positive',res.message)
                    this.checkDatas.dcmReportFilesList =[] // 清空影像资料
                    console.log(res);
                    // console.log(this.checkData.reject);
                    if(res.data.order.informationList){
                        res.data.order.informationList.map(item=>{
                            if(item.informationType == 1){
                                this.checkDatas.dcmReportFilesList.push(item)
                            }
                        })
                    }
                    this.ordervisible = false
                }else{
                    notifys('negative',res.message)
                    this.ordervisible = false
                }
            },1000)
            
        },300),
        //影像资料下载
        yxdownload:makeDebounce(async function(row) {
            informationdownload(
                row, //所下载的id信息
                this.downdata
            )
        },500),
        //下载生成进度条关闭时
        downhide(){
            if (this.downdata.downjindu) {
                console.log("结束了");
                this.downdata.timerList.forEach((item, index) => {
                    window.clearInterval(item);
                })
                this.downdata.timerList = [];
                this.downdata.downjindu = 0;
            }
            this.downdata.downconfig =false
            this.downdata.downdataA = false
        },
        
        //下载重建报告
        async recondownload(row){
            console.log(row);
            const params={
                downloadUrl: row.downloadUrl,
                fileName: row.reconstructionReport
            }
            this.$q.dialog({
                title: '文件下载',
                message: `你是否要下载${row.reconstructionReport}文件`,
                ok: { label: '确定',color: 'blue-6'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
                const url = window.origin +
                "/order/download/file?downloadUrl=" +
                encodeURIComponent(params.downloadUrl) +
                "&fileName=" +
                encodeURIComponent(params.fileName);
                window.open(url);
            })
        },
        closedialog(){
            this.$emit('closedialog')
            console.log('A');
        },
        // 点击同意时
        agreecheck(){
            this.$q.dialog({
                title: '通过',
                message: `你确定要让此重建资料审核任务通过吗?`,
                ok: { label: '确定'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
            try{
                const { data: res } = await reconCheckagree({ ids: this.checkDatas.reconCheckId })
                console.log(res);
                if(res.code===20000){
                    this.$emit('checksuccess')
                    this.$emit('closedialog')
                    notifys('positive',res.message)
                }else{
                    notifys('negative',res.message)
                }
                }catch (e){
                    console.log(e);
                }
            })
        },
        // 点击驳回时
        reject(){
            this.card=true
        },
        // 提交驳回
        async rejectcheck(){
            this.$q.dialog({
                title: '驳回',
                message: `你确定要驳回此订单吗?`,
                ok: { label: '确定'},
                cancel: { label: '取消',flat: true},
            }).onOk(async() => {
            try{
                const { data: res } = await reconCheckreject({ 
                    id: this.checkDatas.reconCheckId,
                    comment: this.rejectreason
                })
                console.log(res);
                if(res.code===20000){
                    this.card = false
                    this.rejectreason=''
                    this.$refs.reject.resetValidation()  //需要修改点击X时 需要清理驳回信息
                    this.$emit('checksuccess')
                    this.$emit('closedialog')
                    notifys('positive',res.message)
                }else{
                    notifys('negative',res.message)
                }
                }catch (e){
                    console.log(e);
                }
            })
        },
        checkjieguo(){
            window.open("/#/viewer?code=" + this.checkAllDatas.reconstructionVo.accessCode);
        },
        hide(){
            this.rejectreason=''
        }
    },
};
</script>

<style lang='less' scoped>
.my-card{
  width: 100%;
  margin-bottom: .5rem;
}
.add {
    width: 100%;
    margin-left: 0;
    margin-top: 0.1875rem;
}
.caozuobtn {
    margin: .9375rem 0 0 0;
    display: flex;
    justify-content: space-between;
    .checkbtn{
        width: 6.25rem;
    }
}
.q-th{
    height: 0;
}
.imgcard{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}
.rejectinput{
    max-height: 12.5rem;
    width: 12.5rem;
    width: 100%;
    margin-left: 0;
    margin-top: 0.1875rem;
}
.maxgao{
    max-height: 50vh;
    overflow: auto;
}
/deep/.el-dialog__body{
    padding: 0 10px 10px !important;
}
/deep/.el-dialog__header {
    padding: 20px 20px 0 !important;
}
</style>
