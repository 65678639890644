<template>
  <div id="OrderResult" class="q-pa-md q-gutter-sm">
    <!-- 面包屑 -->
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
      <q-breadcrumbs-el label="订单管理" icon="iconfont icon-dingdanguanli" />
      <q-breadcrumbs-el label="重建结果审核" icon="iconfont icon-jieguoshenhe_huaban1" to="order/result/list" />
    </q-breadcrumbs>
    <div class='OrderResultbox' id='OrderResultbox'>
      <!-- 搜索组件 -->
      <q-card class="my-card" style="margin-bottom: 10px;">
        <q-card-section>
          <div class="row" id="searchbox">
            <div class="col-9 search">
              <q-input class='operation' :dense='true' outlined  v-model="queryData.patientName" @blur='search' label="患者姓名">
                <template v-if="queryData.patientName" v-slot:append>
                  <q-icon name="close" @click.stop="inputClear" class="cursor-pointer" style="font-size: 16px;"/>
                </template>
              </q-input>
              <selectq class='operation'
                :disable='Permissionslist.filter(item=>item==="result.list").length>0?false:true'
                @bian='search' @clear='search' :options='resultStatus' :model.sync='informationmodel' :label='"资料审核状态"' 
              />
              <selectq class='comboOperation'
                :disable='Permissionslist.filter(item=>item==="result.list").length>0?false:true'
                @bian='search' @clear='search' :options='comboList' :model.sync='comboId' :label='"套餐"' 
              />

              <!-- <el-button
                :disabled='Permissionslist.filter(item=>item==="result.list").length>0?false:true'
                class='resetquery'
                type="primary"
                size='medium'
                @click='search'
              >
              搜索
              </el-button> -->
              <el-button
                :disabled='Permissionslist.filter(item=>item==="result.list").length>0?false:true'
                class='resetquery'
                type="primary"
                size='medium'
                @click='chongzhi'
              >
              重置
              </el-button>
              
            </div>
            <div class="col  addbtn">
              
              <el-button
                :disabled='Permissionslist.filter(item=>item==="result.check").length>0?false:true'
                type="primary"
                size='medium'
                @click='Batchreceive'
              >
              领取任务
              </el-button>
            </div>
          </div>
        </q-card-section>
      </q-card>
      <!-- 列表区域 -->
      <q-card class="my-card">
        <!-- 筛选区域 -->
        <!-- <q-card-section class='tabletop'>
          
        </q-card-section> -->
        <q-card-section>
          <el-table
          v-loading="ordervisible"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(55,55,55,0.2)"
          ref="multipleTable"
          :data="reconlist"
          tooltip-effect="dark"
          @sort-change="sortthiscolumn"
          :max-height="tableHeight"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          >
          <template slot="empty">
            {{nodatetype}}
          </template>
          <el-table-column align='center' type="selection" width="55" fixed="left"></el-table-column>
          <!-- 订单号 -->
          <el-table-column sortable="custom" show-overflow-tooltip v-if="orderID" align='center' prop="orderId" label="订单号" min-width="93px"></el-table-column>
          <!-- 患者姓名 -->
          <el-table-column sortable="custom" v-if="patient" align='center' prop="patientName"  label="患者姓名" min-width="104px" ></el-table-column>
          <!-- 加急订单 -->
          <el-table-column v-if="Urgent && urgenthide" align='center' width='70px'>
            <template slot-scope="{row}">
              <el-tag v-if='row.urgentStatus' type="danger" style='padding:0 5px;'>加急</el-tag>
              <el-tag v-if='row.weekDay' type="danger" style='padding:0 5px;'>周末</el-tag>
            </template>
          </el-table-column>
          <!-- 主管医师 --> 
          <el-table-column v-if="attendingDoctor" sortable="custom" show-overflow-tooltip align='center' prop="attendingDoctor" label="主管医师" min-width='104px'></el-table-column>
          <!-- 资料审核员 -->
          <el-table-column sortable="custom" v-if="informationU" align='center' prop="informationCheckUser" label="资料审核员" min-width="114px"></el-table-column>
          <!-- 重建人员 -->
          <el-table-column sortable="custom" v-if="reconU" align='center' prop="reconUser" label="重建人员" min-width="104px"></el-table-column>
          <!-- 结果审核员 -->
          <el-table-column sortable="custom" v-if="checkU" align='center' prop="checkUser" label="结果审核员" min-width="114px">
            <template slot-scope="checkU">
              {{ checkU.row.checkUser?checkU.row.checkUser: ''}}
            </template>
          </el-table-column>
          <!-- 套餐名称 -->
          <el-table-column sortable="custom" v-if="combo" show-overflow-tooltip align='center' prop="comboName" label="套餐名称" min-width="104px"></el-table-column>
          <!-- 重建报告 -->
          <el-table-column sortable="custom" v-if="reconR" show-overflow-tooltip align='center' prop="reconstructionReport" label="重建报告" min-width="104px">
            <template slot-scope="recon">
              <span v-if='recon.row.reconstructionReport' @click='xzrecon(recon.row)' style="cursor: pointer">
                <q-icon name="iconfont icon-xiazai"/>
                {{recon.row.reconstructionReport}}
              </span>
            </template>
          </el-table-column>
          <!-- 驳回信息 -->
          <el-table-column sortable="custom" v-if='bohui' show-overflow-tooltip align='center' prop="comments" label="驳回信息" min-width="104px">
            <template slot-scope="bohui">
              {{ (bohui.row.comments ? bohui.row.comments : '--') }}
            </template>
          </el-table-column>
          <!-- 重建时间 -->
          <el-table-column sortable="custom" v-if="mTime" align='center' prop="modifyTime" label="重建时间" min-width="151px"></el-table-column>
          <!-- 手术时间 -->
          <el-table-column sortable="custom" v-if="sTime" align='center' prop="surgeryDate" label="手术时间" min-width='155px' ></el-table-column>
          <!-- 创建时间 -->
          <el-table-column sortable="custom" v-if="cTime" align='center' prop="createTime" label="创建时间" min-width='155px' ></el-table-column>
          <!-- 提取码 -->
          <el-table-column sortable="custom" v-if="accessCode" align='center' prop="accessCode" label="提取码" min-width="93px" ></el-table-column>
          <!-- 审核状态 -->
          <el-table-column sortable="custom" v-if="informationS" align='center' prop="informationStatus" label="审核状态" min-width="117px">
            <template slot-scope="Types">
              <q-chip square :color="checkStatuscolor[Types.row.informationStatus]" text-color="white">
                {{ (informationStatus.filter(item => item.key == Types.row.informationStatus ).pop() || {}).value }}
              </q-chip>
            </template>
          </el-table-column>
          <el-table-column align='center' width="200" fixed="right">
            <!-- 操作表头 -->
            <template slot="header">
              <div class='newCaozuobox'>
                <div class='czTit'>操作</div>
                <div class='czpopoverbtn'>
                  <el-popover
                    placement="bottom"
                    width="160"
                    trigger="click">
                      <q-checkbox v-model="orderID" label="订单号" color="cyan" />
                        <q-checkbox v-model="patient" label="患者姓名" color="cyan" />
                        <q-checkbox v-model="Urgent" label="订单加急" color="cyan" />
                        <q-checkbox v-model="attendingDoctor" label="主管医师" color="cyan" />
                        <q-checkbox v-model="informationU" label="资料审核员" color="cyan" />
                        <q-checkbox v-model="reconU" label="重建人员" color="cyan" />
                        <q-checkbox v-model="checkU" label="结果审核员" color="cyan" />
                        <q-checkbox v-model="combo" label="套餐名称" color="cyan" />
                        <q-checkbox v-model="reconR" label="重建报告" color="cyan" />
                        <q-checkbox v-model="bohui" label="驳回信息" color="cyan" />
                        <q-checkbox v-model="mTime" label="重建时间" color="cyan" />
                        <q-checkbox v-model="sTime" label="手术时间" color="cyan" />
                        <q-checkbox v-model="cTime" label="创建时间" color="cyan" />
                        <q-checkbox v-model="accessCode" label="提取码" color="cyan" />
                      <q-checkbox v-model="informationS" label="审核状态" color="cyan" />
                    <el-button icon="el-icon-s-grid" size='medium' slot="reference" style="padding: 5px 10px;border: none;">
                      <i class="el-icon-caret-bottom"></i>
                    </el-button>
                  </el-popover>
                </div>
              </div>
            </template>
            
            <template slot-scope="scope">
              <!-- 领取任务  :disable='scope.row.checkStatus==0? false : true'-->
              <q-btn
                :disable='Permissionslist.filter(item=>item==="result.check").length>0 && scope.row.informationStatus==6? false : true'
                class="czbtn" 
                size="sm" 
                color="blue" 
                icon="iconfont icon-lingqu"
                @click='Reconclaim(scope.row)'>
                <q-tooltip content-class="bg-blue" :offset="[10, 10]">
                  领取任务
                </q-tooltip>  
              </q-btn> 
              <!-- 提交审核 -->
              <!--  -->
              <q-btn 
                :disable='Permissionslist.filter(item=>item==="result.check").length>0 && scope.row.informationStatus!=6? false : true'
                class="czbtn"  
                size="sm" 
                color="green-5" 
                icon="iconfont icon-dingdanshenhe"
                @click='Checkagree(scope.row)'>
                <q-tooltip content-class="bg-green-5" :offset="[10, 10]">
                  审核重建任务
                </q-tooltip>  
              </q-btn>
            </template>
          </el-table-column>
          
        </el-table>
          <!-- 分页组件 -->
        <elementPagination class="Paginationstyle" :total='total' :queryData='queryData' @getList='getreconList()' @changepageSize='changepageSize' @changePage='changePage'/>
        </q-card-section>
      </q-card>
      <!-- 重建审核组件 -->
        <el-dialog :visible.sync="agreeconfirm" width="70%" title="重建审核" :close-on-click-modal='false' @close="agreeconfirm = false">
          <checktask
            v-if="agreeconfirm"
            class="el-dialog__body"
            ref='checkagree'
            :checkAllData.sync='agreeAllData'
            :checkData.sync='agreeData'
            @closedialog='agreeconfirm = false'
            @checksuccess='getreconList()'
          >
          </checktask>
        </el-dialog> 
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import selectq from '@/components/Order/select/index2.vue' // 下拉框组件
import elementPagination from '@/components/ElementUI/elementPagination.vue'; // 分页组件
import checktask from '@/components/Reconresult/reconstructionagree.vue' // 审核任务组件
import { getprovince,getcity,gethosplist,getdepart,getcombo,getoptions } from '@/api/components/checkcitys.js'; // 获取城市接口
import { makeDebounce } from "@/utils/debounce.js";

import { orderCheck,reconCheckList,reconCheckclaim,comboListAll } from '@/api/orderresult.js'; // 重建结果增删改查接口
import { getHeight } from '@/api/getHeight.js'; // 获取列表最高高度
import jwt from "jsonwebtoken";
import {
  getItem,
} from '@/utils/storage.js'
import { notifys } from '@/api/components/Notify.js'

export default {
    created(){
      this.getreconList()
      this.getCombo()
      document.onkeydown=e=>{
        let _key = window.event.keyCode;
        if(_key===13){
          this.search()
        }  
      }
    },
    data() {
      return {
        tableHeight:200, // 列表最大高度

        ordervisible: false, //控制loading
        nodatetype:'', //无内容时显示

        comboList:[], //套餐搜索框数据

        checkAlllist:{
          province:[],
          city:[],
          hosp:[],
          depart:[],
          combo:[],
        },
        checkStatuscolor:{ //资料状态
          0: 'primary', //资料待审核
          1: 'primary', //资料审核中
          2: 'negative', //资料被驳回
          3: 'indigo-5', //资料待重建
          4: 'indigo-5', //三维重建中
          5: 'indigo-5', //重建结果已上传
          6: 'indigo-5', //重建结果待审核
          7: 'indigo-5', //重建结果审核中
          8: 'negative', //重建结果被驳回
          9: 'positive', //重建完成
        },
        // 分页设置
        queryData:{ // element分页信息
          page: 1,
          pageSize: 10,
          patientName:'', // 患者名称
          // orderId: '', // 订单号
        },
        sortField:'', // 控制那列排序
        sortType:'', // 控制排序类型

        informationmodel:'', //重建审核状态
        comboId:'', //套餐id
        total:0, //总条数
        reconlist:[], //列表总条数
        urgenthide: false,//是否展示加急列

        multipleSelection:[],

        patient: true, //控制患者列
        combo: true, //控制套餐列
        reconR: true, //控制重建报告
        mTime: true, //控制重建时间 
        accessCode: true, //控制提取码
        informationS: true, //控制资料处理状态
        Urgent: true, //控制订单加急

        attendingDoctor:false, //主管医师
        bohui: false, // 控制驳回信息
        orderID: false, //控制订单号
        informationU: false, //控制资料审核员
        reconU: false, //控制重建人员
        checkU: false, //控制重建结果审核员
        sTime: false, //控制手术时间
        cTime: false, //控制创建时间 
        orderID: false, //控制订单号

      //审核所需资料
        agreeconfirm: false, //控制审核
        agreeAllData:null, // 查看订单的总信息
        agreeData:{ // 查看用户所需数据
          reconstructionReportList:[], // 重建报告
          checkReportFilesList:[],//检查报告资料
          dcmReportFilesList:[], //影像资料报告
          reject:'',
          reconCheckId:'', // 审核任务id
          disable: null, //控制是否可以驳回和审核通过

          returnedAmtDisable: null, //控制是否显示回款金额
          userType:'', //用户类型
          discountCode:'', //暂时的优惠码
          discountPercent:'', //折扣力度
          urgentAmt:'', //加急金额
          orderReturnedAmt:'', //回款金额
        },

      };
    },
    components:{
      elementPagination, //分页
      checktask, //审核页面
      selectq,
    },
    computed:{
      ...mapState([
        'informationStatus', // 资料审核状态
        'resultStatus', //三维重建状态
        'Permissionslist' // 权限词典
      ]),
    },
    watch:{
    },
    methods: {
      async getCombo(){
        const { data: res } = await comboListAll()
        console.log('套餐',res);
        if(res.code == 20000){
          this.comboList = res.data.combos
        }
        
      },
      //获取重建结果列表
      getreconList(ordername,sortType){
        this.nodatetype=''
        this.ordervisible = true
        this.urgenthide = false //默认隐藏加急列

        setTimeout(async() =>{
          const { data: res } = await reconCheckList({
            ...this.queryData,
            informationStatus: this.informationmodel.key,
            comboId: this.comboId.key,
            sortField: ordername, //给哪一列排序
            sortType: sortType, //排序类型升序或降序
          })
          console.log(res);
          if(res.code==20000){
            this.reconlist= res.data.orders
            this.reconlist.map((e)=>{
              if(e.urgentStatus || e.weekDay){
                //有加急订单
                this.urgenthide = true //显示加急列
              }
            })
            this.total = res.data.totals * 1
          }
          
          let height = document.getElementById('searchbox').offsetHeight + 60
          this.tableHeight = getHeight('OrderResultbox',height)

          this.ordervisible = false
          this.nodatetype='暂无数据'
        },500)
        
      },
      sortthiscolumn(column){
        console.log(column);
        console.log(column.order);
        if(column.order=='ascending'){
          // ascending 为升序
          this.getreconList(column.prop,1)
          this.sortField = column.prop
          this.sortType = 1
        }else if(column.order=='descending'){
          // ascending 为降序
          this.getreconList(column.prop,0)
          this.sortField = column.prop
          this.sortType = 0
        }else{
          // 默认按照时间排序排序
          this.getreconList('createTime',0)
          this.sortField = 'createTime'
          this.sortType = 0
        }
      },
      //批量领取任务
      async Batchreceive(){
        const data = this.multipleSelection.map(item=>{
          return item.id
        })
        this.$q.dialog({
          title: '领取任务',
          message: `你确定要领取已勾选的重建结果审核任务吗?`,
          ok: { label: '确定'},
          cancel: { label: '取消',flat: true},
        }).onOk(async() => {
          try{
            const { data: res } = await reconCheckclaim({ids: data.join(',')})
            // console.log(res);
            if(res.code===20000){
              this.getreconList()
              notifys('positive',res.message)
            }else{
              notifys('negative',res.message)
            }
          }catch (e){
            console.log(e);
          }
        })
      },
      // 单个领取
      Reconclaim(row){
        console.log(row);
        this.$q.dialog({
          title: '领取任务',
          message: `你确定要领取此重建结果审核任务吗?`,
          ok: { label: '确定'},
          cancel: { label: '取消',flat: true},
        }).onOk(async() => {
          try{
            const { data: res } = await reconCheckclaim({ids: row.id})
            // console.log(res);
            if(res.code===20000){
              this.getreconList()
              notifys('positive',res.message)
            }else{
              notifys('negative',res.message)
            }
          }catch (e){
            console.log(e);
          }
        })
      },
      //点击审核时
      Checkagree:makeDebounce(async function(row){
        const token = getItem('token');
        this.agreeData.userType = jwt.decode(token).userType

        console.log(row);
        this.agreeData.reconCheckId = row.id
        const {data:res} = await orderCheck({orderId:row.orderId})
        console.log(res);
        if(res.code===20000){
          this.agreeAllData = res.data.order //储存总数据
          this.agreeData.disable = this.agreeAllData.reconstructionVo.informationStatus == 7? false: true;
          // 控制是否显示回款金额 只有商务和系统管理员可以显示回款金额
          this.agreeData.returnedAmtDisable = this.agreeData.userType ==2 || this.agreeData.userType ==3 ? true : false, 

          // 回款金额
          this.agreeData.orderReturnedAmt = this.agreeAllData.orderReturnedAmt ? this.agreeAllData.orderReturnedAmt: ''
          // 加急金额
          this.agreeData.urgentAmt = this.agreeAllData.urgentAmt ? this.agreeAllData.urgentAmt: ''
          // 优惠码
          this.agreeData.discountCode = this.agreeAllData.discountCode ? this.agreeAllData.discountCode: ''
          //折扣力度
          this.agreeData.discountPercent = this.agreeAllData.discountPercent ? this.agreeAllData.discountPercent: ''

          console.log(this.agreeAllData);
          this.agreeData.reconstructionReportList = [] //清空重建报告
          this.agreeData.checkReportFilesList = [] //清空检查报告
          this.agreeData.dcmReportFilesList =[] // 清空影像资料
          this.agreeconfirm = true
          if (this.agreeAllData.reconstructionVo.comments) {
            this.agreeData.reject = `${this.agreeAllData.reconstructionVo.comments}`
          }
          this.agreeData.reconstructionReportList=[this.agreeAllData.reconstructionVo] 
          console.log(this.agreeData.reconstructionReportList);
          console.log(this.agreeAllData.informationList);
          if(this.agreeAllData.informationList){
            this.agreeAllData.informationList.map(item=>{
              if(item.informationType == 0){
                this.agreeData.checkReportFilesList.push(item)
              } else{
                this.agreeData.dcmReportFilesList.push(item)
              }
            })
          }
        }
      }),
      //点击下载重建报告
      xzrecon(row){
        const params={
            downloadUrl: row.downloadUrl,
            fileName: row.reconstructionReport
        }
        //你是否要下载${row.reconstructionReport}
        this.$q.dialog({
          title: '重建报告下载',
          message: `你是否要下载此报告`,
          ok: { label: '确定',color: 'blue-6'},
          cancel: { label: '取消',flat: true},
        }).onOk(async() => {
          const url = window.origin +
          "/order/download/file?downloadUrl=" +
          encodeURIComponent(params.downloadUrl) +
          "&fileName=" +
          encodeURIComponent(params.fileName);
          window.open(url);
        })
      },
      // 患者姓名清空时触发
      inputClear(){
        this.queryData.patientName = ''
        this.search()
      },
      //重置
      chongzhi:makeDebounce(function(){
        this.queryData.page = 1
        this.queryData.patientName = ''
        this.informationmodel = ''
        this.comboId = ''
        this.getreconList()
      },200),
      // 搜索
      search:makeDebounce(function(){
        this.queryData.page = 1
        this.getreconList(this.sortField,this.sortType)
      },200),
      // 复选框被选择时
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      // 分页发送变化函数
      changepageSize(val){
        this.queryData.pageSize = val
        this.getreconList(this.sortField,this.sortType)
      },
      changePage(val){
        this.queryData.page = val
        this.getreconList(this.sortField,this.sortType)
      },
    },
};
</script>

<style lang='less' scoped>
#OrderResult{
  height: 100%;
}
.OrderResultbox{
  height: 90%;
}
.search{
  display: flex;
  flex-direction: row ;
  align-items: flex-end;
  flex-wrap: wrap;
  .operation{
    margin: .625rem .625rem 0 .625rem;
    width: 12.5rem;
  }
  .comboOperation{
    margin: .625rem .625rem 0 .625rem;
    width: 300px;
  }
}
.q-card__section--vert {
  padding: 10px;
}
/deep/.el-table td{
  padding: 8px 0 !important;
}
.newCaozuobox{
  // display: flex;
  // justify-content: space-between;
  .czTit{
    float: left;
    text-align: right;
    width:50%;
  }
  .czpopoverbtn{
    float: left;text-align: right;width:50%;
  }
}
.tabletop{
  display: flex;
  flex-direction:row-reverse;
  padding: .3125rem 1rem;
}
.addbtn{
  display: flex;
  flex-direction: row ;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
}
.resetquery{
  margin: .625rem .625rem 0 .625rem;
}
.czbtn{
  margin-right: .75rem;
}
.Paginationstyle{
  margin-top: .9375rem;
}
.upload{
  display: none;
}
/deep/.el-dialog__body{
  padding: 0 10px 10px !important;
}
</style>
