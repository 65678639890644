import { createAPI } from "../utils/request"

export const reconCheckList = data => createAPI('order/reconCheck/list', 'get', data) //重建结果审核列表

export const reconCheckclaim = data => createAPI('order/reconCheck/claim', 'get', data) //批量领取重建结果任务

export const reconCheckreject = data => createAPI(`order/reconCheck/reject/${data.id}`, 'get', data) //驳回

export const reconCheckagree = data => createAPI('order/reconCheck/agree', 'get', data) //批量审核通过

export const orderCheck = data => createAPI(`order/reconCheck/get/${data.orderId}`, 'get', data) //重建结果订单详细

export const comboListAll = data => createAPI(`base/combo/listAll`, 'get', data) //重建结果订单详细
